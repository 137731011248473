import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GoalSectionHolder } from "components";
import { useInput } from "contexts";
import { useIsMobile } from "hooks";
import { MissionSection } from "./MissionSection";
import { VisionSection } from "./VisionSection";
import { Paper } from "@mui/material";
import { createValueString } from "utils";

const DATA_FORMAT = {
  mission: "",
  visions: ["", "", "", "", ""],
};

const VIEWS = ["Life Purpose or Mission", "3 Year Vision"];

export const Mission = () => {
  const [tab, setTab] = useState(0);
  const { goal, submitSection, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const [initialized, setInitialized] = useState(false);
  const timer = useRef<any>();

  const formik = useFormik({
    initialValues: DATA_FORMAT,
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("mission", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = Object.keys(DATA_FORMAT);
    if (!initialized) return false;
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.mission)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.mission]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  useEffect(() => {
    setError(false);
    try {
      const data = goal && goal.mission;
      if (!data) return;
      let visions = data.visions;
      const vlength = visions.length;
      if (vlength < 5) visions.push(...Array(5 - vlength).fill(""));
      formik.setValues({
        mission: data.mission || "",
        visions,
      });
      formik.setValues(data);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.mission]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Mission OR Purpose"
      onSave={formik.submitForm}
    >
      {!isSmallMobile && (
        <Tabs
          scrollButtons="auto"
          value={tab}
          onChange={(e, newTab) => setTab(newTab)}
        >
          {VIEWS.map((view, index) => (
            <Tab label={view} key={"tab" + index} />
          ))}
        </Tabs>
      )}
      {isSmallMobile && (
        <Select
          fullWidth
          size="small"
          value={tab}
          onChange={(e) => setTab(Number(e.target.value))}
        >
          {VIEWS.map((view, index) => (
            <MenuItem value={index} key={"select" + index}>
              {view}
            </MenuItem>
          ))}
        </Select>
      )}
      <Box component={"form"} onSubmit={formik.handleSubmit}>
        <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
          {tab == 0 && <MissionSection formik={formik} />}
          {tab == 1 && <VisionSection formik={formik} />}
        </Paper>
        <button style={{ display: "none" }} type="submit"></button>
      </Box>
    </GoalSectionHolder>
  );
};
