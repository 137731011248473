import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";

export const VisionSection: FC<{ formik: FormikProps<any> }> = ({ formik }) => {
  return (
    <Box sx={{ "& > *:not(:last-child)": { pb: 2 } }}>
      {formik.values.visions.map((value: string, key: number) => (
        <FormControl key={key + "vision"} fullWidth>
          <FormLabel sx={{ pb: 1 }}>Vision #{Number(key) + 1}</FormLabel>
          <TextField
            value={value}
            onChange={(e) => {
              formik.setFieldValue(`visions[${key}]`, e.target.value);
            }}
            multiline
            minRows={3}
          />
        </FormControl>
      ))}
    </Box>
  );
};
