import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useInput } from "contexts";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { GoalSectionHolder } from "components";
import { useIsMobile } from "hooks";
import { createValueString } from "utils";

export const MINDSET_FIELDS = [
  {
    id: "personal",
    label: "Personal",
  },
  {
    id: "health",
    label: "Health",
  },
  {
    id: "business",
    label: "Business",
  },
  {
    id: "intimateRelationship",
    label: "Intimate Relationship",
  },
  {
    id: "otherRelationships",
    label: "Other Relationships",
  },
  {
    id: "contribution",
    label: "Contribution",
  },
  {
    id: "funAndAdventure",
    label: "Fun and Adventure",
  },
  {
    id: "finances",
    label: "Finances",
  },
];

const MindsetField: FC<{ id: string; formik: any; multiline?: boolean }> = ({
  id,
  formik,
  multiline,
}) => {
  return (
    <TextField
      multiline={multiline}
      minRows={2}
      name={id}
      onChange={formik.handleChange}
      value={(formik.values as any)[id] || ""}
      fullWidth
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment
            sx={
              multiline
                ? {
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }
                : {}
            }
            position="start"
          >
            I am
          </InputAdornment>
        ),
      }}
      size="small"
      prefix="$"
    />
  );
};

export const Mindset = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>();
  const [initialized, setInitialized] = useState(false);
  const { isMobile } = useIsMobile();

  const formik = useFormik({
    initialValues: {},
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("mindset", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    if (!initialized) return false;
    try {
      const order = MINDSET_FIELDS.map(({ id }) => id);
      if (goal.mindset === undefined) return false;
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.mindset)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.mindset]);

  useEffect(() => {
    setError(false);
    if (!goal.mindset) return;
    try {
      const values: any = {};
      MINDSET_FIELDS.map(({ id }) => (values[id] = ""));
      if (goal && goal.mindset) {
        MINDSET_FIELDS.map(({ id }) => (values[id] = goal.mindset[id]));
      }
      formik.setValues(values);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.mindset]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Mindset"
      onSave={formik.submitForm}
    >
      <Typography>
        What beliefs do you need to adopt to feel in mindset with your vision
        for the following areas?
      </Typography>
      <Box onSubmit={formik.handleSubmit} component={"form"} pt={3}>
        <Table
          size={isMobile ? "medium" : "small"}
          padding="normal"
          sx={{ width: "100%" }}
        >
          <TableBody>
            {MINDSET_FIELDS.map((field) => {
              return (
                <TableRow key={field.id}>
                  <TableCell sx={{ px: 0 }} width={isMobile ? "100%" : 250}>
                    <Typography pb={isMobile ? 1 : 0}>{field.label}</Typography>
                    {isMobile && (
                      <MindsetField multiline id={field.id} formik={formik} />
                    )}
                  </TableCell>
                  {!isMobile && (
                    <TableCell>
                      <MindsetField id={field.id} formik={formik} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
