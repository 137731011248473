const steps = [
  `Fill out your yearly goals using the toggle at the top. These are goals that you would like to achieve by the end of this calendar year.`,
  `Fill out your quarterly goals by switching the toggle to quarterly. Break down your yearly (annual) goals into four quarterly goals to make them easier to reach.`,
  `Continue to complete each section until finished.`,
  `Download your PDF! You can keep this handy guide with you, bring it to events and use it on our Zoom meetings.`,
];

export const Instructions = () => {
  return (
    <>
      {`This Master Abundance Playbook or MAP for short will help you create and achieve your vision for your life and business. It will also help you set actionable goals. Remember to set goals that are SMART- Specific, Measurable, Action-Oriented, a Reach for you, and Time Sensitive. Its important that we don’t set goals that are actually outcomes. This is not just for your professional and financial goals but also your life goals like health, wealth, contribution, bucket list items and relationships. It will also help you track the numbers so you can see your progress from year to year. The app saves your data as you go.`}
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {steps.map((step, index) => (
          <li key={"step+" + index}>
            <span style={{ fontWeight: "bold" }}>Step {index + 1}: </span>
            {step}
          </li>
        ))}
      </ul>
    </>
  );
};
