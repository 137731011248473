import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useInput } from "contexts";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { GoalSectionHolder } from "components";
import { Stack } from "@mui/material";
import { createValueString } from "utils";
import { Instructions } from "./instructions";

export const Overview = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>(null);
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: { theme: "", help: "" },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("misc", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = ["theme", "help"];
    try {
      if (!initialized) return false;
      if (goal.misc === undefined) return false;
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal.misc)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal, initialized]);

  useEffect(() => {
    setError(false);
    if (!goal.misc) return;
    try {
      formik.setValues(goal.misc);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.misc]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Overview"
      onSave={formik.submitForm}
    >
      <Instructions />
      <Box onSubmit={formik.handleSubmit} component={"form"} pt={3}>
        <Stack spacing={2}>
          <Box>
            <Typography mb={1}>Theme of the year</Typography>
            <TextField
              placeholder="Aligned, Content, Balanced, etc."
              name={"theme"}
              onChange={formik.handleChange}
              value={formik.values.theme || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>
          <Box>
            <Typography mb={1}>
              What do you need help with? Can we support you?
            </Typography>
            <TextField
              name={"help"}
              onChange={formik.handleChange}
              value={formik.values.help || ""}
              fullWidth
              multiline
              minRows={5}
              variant="outlined"
              size="small"
            />
          </Box>
        </Stack>
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
