import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const PublicView = () => {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography align="center" pt={4}>
        Please go to the{" "}
        <Link href="https://members.gobundancewomen.com/" target="_blank">
          members portal
        </Link>{" "}
        to login to the goal tracker
      </Typography>
    </Box>
  );
};
