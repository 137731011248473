import { FC } from "react";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { localizeNumber } from "utils";

const getLastQuarterValue = (goal: any, section: string, id: string) => {
  try {
    return goal._previous[section][id];
  } catch {
    return 0;
  }
};

export const AverageCell: FC<{
  section: string;
  id: string;
  prepend?: string;
  goal: any;
}> = ({ section, id, prepend, goal }) => {
  const value = (() => {
    try {
      return Number(Number(goal._averages[section][id]).toFixed(2));
    } catch {
      return "N/A";
    }
  })();
  return (
    <TableCell>
      {prepend && prepend + " "}
      {localizeNumber(value)}
    </TableCell>
  );
};

export const LastQuarterCell: FC<{
  section: string;
  id: string;
  prepend?: string;
  goal: any;
}> = ({ section, id, prepend, goal }) => {
  const value = getLastQuarterValue(goal, section, id);
  return (
    <TableCell>
      {prepend && prepend + " "}
      {localizeNumber(value)}
    </TableCell>
  );
};

export const DifferenceCell: FC<{
  against?: number;
  section?: string;
  id?: string;
  current?: any;
  prepend?: string;
  value?: any;
  append?: string;
  goal: any;
}> = ({ section, id, current, prepend, value, append, goal, against }) => {
  const difference = (() => {
    if (value || value === 0) return value;
    try {
      if (!section || !id) return 0;
      if (against) return Number(current) - Number(against);
      const lastValue = getLastQuarterValue(goal, section, id);
      const _diff = Number(current) - lastValue;
      if (isNaN(_diff)) return "N/A";
      return _diff;
    } catch {
      return "N/A";
    }
  })();

  return (
    <TableCell>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        {difference !== "N/A" && Number(difference) === 0 && (
          <HorizontalRuleIcon color="info" fontSize="small" />
        )}
        {difference !== "N/A" && Number(difference) > 0 && (
          <ArrowUpwardIcon color="success" fontSize="small" />
        )}
        {difference !== "N/A" && Number(difference) < 0 && (
          <ArrowDownwardIcon color="error" fontSize="small" />
        )}
        <Box>
          {prepend && prepend + " "}
          {localizeNumber(difference)}
          {append && " " + append}
        </Box>
      </Box>
    </TableCell>
  );
};
