import {
  Box,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useInput } from "contexts";
import { FormikProps, useFormik } from "formik";
import { FC } from "react";
import { localizeNumber } from "utils";

const InputRow: FC<{ label: string; id: string; formik: FormikProps<any> }> = ({
  label,
  id,
  formik,
}) => {
  const name = `goals.${id}`;

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <Typography fontSize={20} minWidth={150}>
        {label}
      </Typography>
      <TextField
        autoComplete="off"
        name={name}
        onChange={(e) => {
          const value = Number(e.target.value);
          if (isNaN(Number(value))) return;
          if (Number(value) < 0) return;
          formik.setFieldValue(name, value);
        }}
        value={(formik.values as any)["goals"][id]}
        fullWidth
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </Box>
  );
};

export const Goals: FC<{ formik: FormikProps<any> }> = ({ formik }) => {
  const { selectedYear } = useInput();
  return (
    <Box pt={2} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>What are your goals for {selectedYear}?</Typography>
        <InputRow label="Assets" formik={formik} id="assets" />
        <InputRow label="Liabilities" formik={formik} id="liabilities" />
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography fontSize={20} minWidth={150}>
            Net Worth
          </Typography>
          <Typography fontSize={20} fontWeight={"bold"}>
            ${" "}
            {localizeNumber(
              formik.values.goals.assets - formik.values.goals.liabilities
            )}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};
